<template>
	<vue-route-transition id="app" :keepAlive="true"></vue-route-transition>
	<!-- <div id="app">
		<router-view></router-view>
	</div> -->
</template>
<script>

export default {
	
}
</script>

<style lang="css">
* {
	content: normal !important;
}
html,
body {
	background: #fff;
	overflow-x: hidden;
}
html,body,p,h1,h2,h3,h4,h5 {
	padding: 0;
	margin: 0;
}
 .vue-route-transition-content{
	width: 100%;
	height: 100vh;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	flex: 1;
	overflow-y: auto;
	/* overflow-x: hidden; */
	position: relative;
}

/* 页面公共样式 */
.van-tabbar-item{
	color: #B3B5B6 !important;
}
.van-swipe__indicators{
	bottom:.23rem !important;
}
.van-tabbar-item--active{
	color: #567cf3 !important;
}
.amap-logo{
	display: none!important;
}
.amap-copyright{
	opacity:0;
}
.page{
	background: #fff;
	min-height: 100%;
}
.van-dialog__confirm, .van-dialog__confirm:active{
	color: #4088EE !important;
}
.van-loading{
	text-align: center;
}
.dropmenu .van-dropdown-menu__title{
	font-size: .37rem !important;
}
.dropmenu .van-cell{
	font-size: .37rem !important;
}
.van-swipe__indicator--active{
	background-color: #567cf3 !important;
}
.van-dropdown-menu__bar{
	width: 100%;
	height: 1rem!important;
	box-shadow: none !important;
}
 .van-dropdown-menu__title {
  font-size: .37rem !important;
}
.radioItem .van-icon {
  opacity: 0 !important;
}

.radioItem.active span{
	color: #fa683b;
}
.form .van-cell__right-icon{
  color: #ccc !important;
}
.checkItem .van-checkbox__icon {
	 opacity: 0 !important;
}
.checkItem span{
	margin-left: -.2rem !important;
	font-size: .34rem;
}
.checkItem.active span{
	color: #fff;
}
.van-picker__confirm{
	color:#567cf3!important;
}
.radioItem{
	position: relative;
}
.radioItem span{
	margin-left: 0 !important;
  position: absolute;
	left:0;
	top:0;
	right:0;
	bottom:0;
	text-align: center;
	font-size: .34rem;
	line-height: .8rem;
}
.dataLoading{
	padding-top: 1rem;
}
.loading{
	position: fixed !important;
	left:50%;
	top:50%;
	transform: translate(-50%,-50%);
	z-index: 20;
}
.body p{
  font-size: .36rem ;
}
.van-empty__description{
	margin-top: 0rem !important;
}
.pull-refresh{
	min-height: 100vh;
}
</style>
