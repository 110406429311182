import Vue from 'vue'
import axios from 'axios'
import baseConfig from '@/utils/config'
import { storage } from '@/utils/utils'

let $storage = new storage()

export function request(config) {
    const instance = axios.create({
            baseURL: baseConfig.baseUrl,
            timeout: 5000
        })
        //请求拦截
    instance.interceptors.request.use(config => {
        let token = $storage.get("token")
        if (token) {
            config.headers.token = `${token}`
        } else {

        }
        return config
    }), err => {

    }

    instance.interceptors.response.use(res => {
        return res.data
    }), err => {

    }

    return instance(config)
}



export function http(config) {
    const instance = axios.create({
            baseURL: baseConfig.baseUrl,
            timeout: 5000
        })
        //请求拦截
    instance.interceptors.request.use(config => {
            let token = $storage.get("token")
            if (token) {
                config.headers.token = `${token}`
            } else {

            }
            this.$toast.loading({
                forbidClick: true,
                loadingType: 'spinner',
                duration: 0
            })
            return config
        }), err => {

        }
        //响应拦截
    instance.interceptors.response.use(res => {

        return res.data
    }), err => {

    }
    return instance(config)
}



export function authRequest(config) {
    const instance = axios.create({
            baseURL: baseConfig.authUrl,
            timeout: 5000
        })
        //请求拦截
    instance.interceptors.request.use(config => {
        return config
    }), err => {

    }

    instance.interceptors.response.use(res => {
        return res.data
    }), err => {

    }

    return instance(config)
}