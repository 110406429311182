import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Index = () =>
    import ("@/views/zulin/index/Index")
const Search = () =>
    import ("@/views/zulin/search/Search")
const Detail = () =>
    import ("@/views/zulin/detail/Detail")
const Near = () =>
    import ("@/views/zulin/detail/Near")
const Location = () =>
    import ("@/views/zulin/detail/Location")
const Form = () =>
    import ("@/views/zulin/form/Form")
const Home = () =>
    import ("@/views/easy/home/Home")
const newsList = () =>
    import ("@/views/easy/news/newsList")
const newsDetail = () =>
    import ("@/views/easy/news/newsDetail")
const FindJobList = () =>
    import ("@/views/easy/job/FindJobList")
const FindJobDetail = () =>
    import ("@/views/easy/job/FindJobDetail")
const FindJob = () =>
    import ("@/views/easy/job/FindJob")
const JobSearch = () =>
    import ("@/views/easy/job/JobSearch")
const RecruitmentList = () =>
    import ("@/views/easy/Recruitment/RecruitmentList")
const RecruitmentDetail = () =>
    import ("@/views/easy/Recruitment/RecruitmentDetail")
const Recruitment = () =>
    import ("@/views/easy/Recruitment/Recruitment")
const RecruitmentSearch = () =>
    import ("@/views/easy/Recruitment/RecruitmentSearch")
const Promote = () =>
    import ("@/views/easy/promote/Promote")
const PromoteList = () =>
    import ("@/views/easy/promote/promoteList")
const PromoteDetail = () =>
    import ("@/views/easy/promote/PromoteDetail")
const PromoteSearch = () =>
    import ("@/views/easy/promote/PromoteSearch")
const House = () =>
    import ("@/views/easy/house/House")
const HouseA = () =>
    import ("@/views/easy/house/HouseA")
const HouseB = () =>
    import ("@/views/easy/house/HouseB")
const HouseC = () =>
    import ("@/views/easy/house/HouseC")
const HouseD = () =>
    import ("@/views/easy/house/HouseD")
const HouseList = () =>
    import ("@/views/easy/house/houseList")
const houseSearch = () =>
    import ("@/views/easy/house/houseSearch")
const HouseDetail = () =>
    import ("@/views/easy/house/HouseDetail")
const User = () =>
    import ("@/views/easy/user/User")
const Collection = () =>
    import ("@/views/easy/user/Collection")
const Release = () =>
    import ("@/views/easy/user/Release")
const MyHistory = () =>
    import ("@/views/easy/user/MyHistory")

const routes = [{
        path: "/",
        redirect: "/Home"
    },
    {
        path: "/Index",
        name: "Index",
        meta: {
            title: "长兴城投房产租赁",
        },
        component: Index
    },
    {
        path: "/Search",
        name: "Search",
        meta: {
            title: "搜索"
        },
        component: Search
    },
    {
        path: "/Detail",
        name: "Detail",
        meta: {
            title: "详情",
            keepAlive: false
        },
        component: Detail
    },
    {
        path: "/Form",
        name: "Form",
        meta: {
            title: "我要租用"
        },
        component: Form
    },
    {
        path: "/Near",
        name: "Near",
        meta: {
            title: "周边配套",
            keepAlive: true
        },
        component: Near
    },
    {
        path: "/Location",
        name: "Location",
        meta: {
            title: "地理位置",
            keepAlive: true
        },
        component: Location
    },
    {
        path: "/Home",
        name: "Home",
        meta: {
            title: "便民信息",
            keepAlive: false
        },
        component: Home
    },
    {
        path: "/User",
        name: "User",
        meta: {
            title: "个人中心"
        },
        component: User
    },
    {
        path: "/Collection",
        name: "Collection",
        meta: {
            title: "我的收藏",
            keepAlive: false
        },
        component: Collection
    },
    {
        path: "/Release",
        name: "Release",
        meta: {
            title: "我的发布",
            keepAlive: false
        },
        component: Release
    },
    {
        path: "/MyHistory",
        name: "MyHistory",
        meta: {
            title: "历史记录",
            keepAlive: false
        },
        component: MyHistory
    },
    {
        path: "/newsList",
        name: "newsList",
        meta: {
            title: "热点资讯",
            keepAlive: true
        },
        component: newsList
    },
    {
        path: "/newsDetail",
        name: "newsDetail",
        meta: {
            title: "热点资讯",
            keepAlive: true
        },
        component: newsDetail
    },
    {
        path: "/Recruitment",
        name: "Recruitment",
        meta: {
            title: "全职招聘",
            keepAlive: false
        },
        component: Recruitment
    },
    {
        path: "/RecruitmentList",
        name: "RecruitmentList",
        meta: {
            title: "全职招聘",
            keepAlive: true
        },
        component: RecruitmentList
    },
    {
        path: "/RecruitmentDetail",
        name: "RecruitmentDetail",
        meta: {
            title: "招聘详情",
            keepAlive: false
        },
        component: RecruitmentDetail
    },
    {
        path: "/RecruitmentSearch",
        name: "RecruitmentSearch",
        meta: {
            title: "招聘搜索",
            keepAlive: true
        },
        component: RecruitmentSearch
    },
    {
        path: "/FindJob",
        name: "FindJob",
        meta: {
            title: "求职简历",
            keepAlive: false
        },
        component: FindJob
    },
    {
        path: "/FindJobDetail",
        name: "FindJobDetail",
        meta: {
            title: "求职详情",
            keepAlive: false
        },
        component: FindJobDetail
    },
    {
        path: "/FindJobList",
        name: "FindJobList",
        meta: {
            title: "求职列表",
            keepAlive: true
        },
        component: FindJobList
    }, {
        path: "/JobSearch",
        name: "JobSearch",
        meta: {
            title: "求职搜索",
            keepAlive: true
        },
        component: JobSearch
    },
    {
        path: "/Promote",
        name: "Promote",
        meta: {
            title: "推广产品",
            keepAlive: false
        },
        component: Promote
    },
    {
        path: "/PromoteList",
        name: "PromoteList",
        meta: {
            title: "推广产品",
            keepAlive: true
        },
        component: PromoteList
    },
    {
        path: "/PromoteDetail",
        name: "PromoteDetail",
        meta: {
            title: "推广产品",
            keepAlive: false
        },
        component: PromoteDetail
    },
    {
        path: "/PromoteSearch",
        name: "PromoteSearch",
        meta: {
            title: "推广产品搜索",
            keepAlive: true
        },
        component: PromoteSearch
    },
    {
        path: "/House",
        name: "House",
        meta: {
            title: "发布房屋出售",
            keepAlive: false
        },
        component: House
    },
    {
        path: "/HouseA",
        name: "HouseA",
        meta: {
            title: "发布房屋出售",
            keepAlive: false
        },
        component: HouseA
    },
    {
        path: "/HouseB",
        name: "HouseB",
        meta: {
            title: "发布房屋出售",
            keepAlive: false
        },
        component: HouseB
    },
    {
        path: "/HouseC",
        name: "HouseC",
        meta: {
            title: "发布房屋出售",
            keepAlive: false
        },
        component: HouseC
    },
    {
        path: "/HouseD",
        name: "HouseD",
        meta: {
            title: "发布房屋出售",
            keepAlive: false
        },
        component: HouseD
    },
    {
        path: "/HouseList",
        name: "HouseList",
        meta: {
            title: "房屋租售",
            keepAlive: true
        },
        component: HouseList
    },
    {
        path: "/houseSearch",
        name: "houseSearch",
        meta: {
            title: "房屋租售搜索",
            keepAlive: true
        },
        component: houseSearch
    },
    {
        path: "/HouseDetail",
        name: "HouseDetail",
        meta: {
            title: "房屋租售",
            keepAlive: false
        },
        component: HouseDetail
    }


]


const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savePosition) {
        if (savePosition) {
            return savePosition
        } else {
            return { x: 0, y: 0 };
        }
    },
})




router.beforeEach((to, from, next) => {
    next()
})

router.afterEach((to, from) => {
    to.meta.title && (document.title = to.meta.title)
})

export default router