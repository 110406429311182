import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        loading: false, //页面加载loading
        dataLoading: false //数据加载loading
    },
    mutations: {
        setLoading(state, val) {
            state.loading = val
        },
        setDataLoading(state, val) {
            state.dataLoading = val
        },
    },
    getters: {

    },
    actions: {}

})

export default store