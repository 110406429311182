let baseUrl
let authUrl
if (process.env.NODE_ENV === 'development') {
    baseUrl = "/api"
    authUrl = "/auth"
} else if (process.env.NODE_ENV === 'debug') {

} else if (process.env.NODE_ENV === 'production') {
    baseUrl = "https://newhouse.zjlaishang.com/"
    authUrl = "https://newhouse.zjlaishang.com/"
}

export default {
    baseUrl,
    authUrl
}