import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import Vant from 'vant'
import axios from 'axios'
import 'vant/lib/index.css'
import 'lib-flexible/flexible'
import RouteTransition from '@/components/vue-route-transition'
import { request, http, authRequest } from '@/request/http'
import { timeFormat, isWX, storage, Debounce } from '@/utils/utils'


Vue.use(Vant)
Vue.use(RouteTransition)

Vue.config.productionTip = false

Vue.prototype.$timeFormat = timeFormat
Vue.prototype.$isWX = isWX()
Vue.prototype.$request = request
Vue.prototype.$authRequest = authRequest
Vue.prototype.$http = http
Vue.prototype.$storage = new storage()
Vue.prototype.$axios = axios
Vue.prototype.$Debounce = Debounce

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')